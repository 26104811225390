import Axios from "axios";

async function getSearchSuggestions(inputValue, type, category = null) {
  let lookupUrl = [`/api/autocomplete/${type}`, inputValue].join('?q=');
  if (category && category !== 'ALL') {
    lookupUrl = `${lookupUrl}&c=${category}`
  }
  try {
    const { data } = await Axios.get(lookupUrl);
    return data.data;
  } catch (error) {
    return [];
  }
}

export default getSearchSuggestions;
